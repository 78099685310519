import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import sticker from "../../images/c8-sticker.png";
class C8 extends Component {
  state = {
    ans: 0,
  };
  render() {
    if (!this.props.data) return <></>;
    return (
      <Container fluid id="faq" className="c8 pe-md-0 c-pad">
        <div className="c-h1 c8-h1">{this.props.data.header}</div>
        <div className="row d-flex">
          <div className="col col-12 col-md-8 c8-accordion">
            {this.props.data.content.slice(0, 3).map((item, index) => {
              return (
                <div className="acc-cont" key={index}>
                  <div
                    className="c8-p1 d-flex justify-content-between align-items-center"
                    onClick={() => {
                      this.setState({ ans: index });
                    }}
                  >
                    {item.question}
                    {this.state.ans === index ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </div>
                  <div
                    className="c8-d1 text-left text-content"
                    style={{
                      display: this.state.ans === index ? "block" : "none",
                    }}
                    dangerouslySetInnerHTML={{__html: item.answer }}
                  />
                </div>
              );
            })}
          </div>
          <div className="ques-div col col-md-4 mt-4 mt-md-0">
            <img
              alt=""
              className="c8-sticker"
              src={sticker}
              height="168px"
              width="auto"
            />
            <div className="c8-ques">Have a Question?</div>
            <div className="c8-ans">
              Check out our answers to frequently asked questions
            </div>
            <div>
              <Button
                className="btn-pri"
                href="/faqs"
                target="_blank"
              >
                View more FAQs
              </Button>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default C8;
